@import "./spinner.css";

@media only screen and (max-width: 425px) {

  .App {
    display: grid;
    grid-template-columns: repeat(auto-fill, 415px);
  }

  .report_loading{
    width: 350px;
    height: 225px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 2vh 1vw 2vh 1vw;
    padding: 2vh;
    background-color: rgb(241, 238, 238);
    display: grid; 
    border: 2px solid rgb(41, 40, 40);
  }

  .report {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
      align-self: start;
    justify-self:start;
    margin: 2vh 1vw 2vh 1vw;
    padding: 2vh;
    background-color: rgb(241, 238, 238);
    display: grid; 
    border: 2px solid rgb(41, 40, 40);
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
  }
  
}

@media only screen and (min-width: 425px) {

  .App {
    display: grid;
    grid-template-columns: repeat(auto-fill, 475px);
  }
  
  .report_loading{
    width: 400px;
    height: 225px;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 2vh 1vw 2vh 1vw;
    padding: 2vh;
    background-color: rgb(241, 238, 238);
    display: grid; 
    border: 2px solid rgb(41, 40, 40);
  }

  .report {
    /* width: 400px; */
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    align-self: start;
    justify-self:start;
    transition: 0.3s;
    margin: 2vh 1vw 2vh 1vw;
    padding: 2vh;
    background-color: rgb(241, 238, 238);
    display: grid; 
    border: 2px solid rgb(41, 40, 40);
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
  }
  
}

header {
  background-color: #282c34;
  min-height: 7vh;
  /* line-height: 2.5vh; */
  font-size: calc(10px + 2vmin);
  font-weight: 700;
  color: white;
  text-align: center;
  vertical-align: middle;
  padding: 3vh 0 3vh 0;
  grid-column: 1 / -1

}

.username {
  text-align: center;
}

.UserProfile {
  padding: 0;
  margin: 0;
  border-bottom: lightgray;
  border-width: 0 0 1px 0;
  border-style: dashed;
  display:  inline-grid; 
  padding: 1vh 0vh 0vh 0vh;
  margin: 0vh 0vh 3vh 0vh;
  grid-column: 1 /-1;
  grid-template-columns: 1fr 1fr;
  background-color: rgb(241, 238, 238);
}

.signIn {
grid-column: 1 /3;
justify-self: start;
width:75px;
height:50px;
border: 2px solid; 
}

.Section__container___BDYar {
grid-column: 1/3;

}

.UserProfile p {
  grid-column: 1 / 3; 
  align-self: center; 
  justify-self: center;
  margin: 0;
}

.signout {
  grid-column:  1 / 2;
  justify-self: start;
  border: 2px solid ; 
}

.delete_account_btn{
  height: 50px;
  width: 75px;
  grid-column: 2 / 3;
  justify-self: end;
  border: 2px solid ; 
} 

.delete_account_btn_final{
  justify-self: start;
  height: 50px;
  width: 75px;
  border: 2px solid ; 
} 

.cancel {
  justify-self: end;
  height: 50px;
  width: 75px;
  border: 2px solid ; 
}

.description {
  text-align: center;
  margin: 1vh 3vh 1vh 3vh;
  grid-column: 1 /-1;
}

.links {
  align-self: center;

}

.footer-links {
  grid-column: 1 /-1;
  display: grid;
  padding: 2vh 0vh 2vh 0vh;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100px 100px 100px;
}

.footer-links a {
  padding: 1vh 1vh 1vh 1vh;
  align-self:center;
  text-align: center;
}


h3 {
  background-color: #282c34;
  min-height: 10vh;
  line-height: 10vh;
  font-size: calc(10px + 2vmin);
  color: white;
  text-align: center;
  vertical-align: middle;
  grid-column: 1 / -1;
}

.subscription {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin: 2vh 3vh 2vh 3vh;
    padding: 2vh;
    background-color: rgb(241, 238, 238);
    display: grid; 
    grid-template-columns: 1fr 125px;
    align-items: center;
}

 .subscription_title {
  font-size: 15px;
  /* justify-self: start; */
}  

.btn-right {
  justify-self: end;
}


/* .report_loading{
  width: 350px;
  height: 225px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin: 2vh 3vw 2vh 3vw;
  padding: 2vh;
  background-color: rgb(241, 238, 238);
  display: grid; 
  border: 2px solid rgb(41, 40, 40);
} */


.home_id {
  font-weight: 500;
  padding: 1vh 0vh 1vh 0vh;
  text-align: left;
}

.home_name {
grid-column: 2/6;
grid-row: 1/2;
text-align: center;
font-weight: 500;
padding: 1vh 1vw 1vh 1vw;
border-bottom: black;
border-bottom-width: 2px;
border-bottom-style: solid;
/* border-left: rgb(189, 187, 187);
border-left-width: 1px;
border-left-style: solid;
border-top: rgb(189, 187, 187);
border-top-width: 1px;
border-top-style: solid; */
}

.home_score_div {
  grid-column: 6/7;
  grid-row: 1/3;
  text-align: center;
  border: 2px solid black;

}

.grade {
  color: white;
  font-size: 3em;
  padding: 0;
  display: block;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
  text-align: center;
}

.grade_yellow {
  background: rgb(224, 212, 37);
}

.grade_red {
  background: rgb(209, 49, 21);
}

.grade_green {
  background: rgb(7, 110, 50);
}

.report_latest {
  grid-column: 1/6;
  grid-row: 2/3; 
  font-weight: 500
}

.report_title {
  grid-column: 1/7;
  text-align: left;
  font-weight: 400;
  padding: 1vh 0vw 0vh 0vw;
  font-size: 1.15em; 
}

.report_pages {
  grid-column: 5/6;
  /* grid-row: 3/4; */
  padding: 0vh 0vh 0vh 0vh; 
  font-size:0.75em;
}

.total_points {
  font-size:0.5em;
  padding: 0vh 0vh 0vh 0vh; 
  text-align: center;
}

.report_date {
  grid-column: 1/4;
  /* grid-row: 3/4; */
  padding: 0vh 0vh 0vh 0vh;
  font-size:1em;
  font-weight: 800;
}

.report_date_inactive {
  /* color: darkgray; */
  font-weight: 400;
}


.report_summary:not(:first-child){
  font-size:0.75em;
  text-align: center;
  padding: 2vh 0vh 4vh 0vh;

}

.expand {
  grid-column: 1/6;
  /* height:50px; */
  padding: 1vh 0vh 2vh 0vh;
  align-self: center;
  justify-self: left;
}

.home_add_notification{
  grid-column: 6/7;
  height:50px;
  text-align: center;
  color: black;
  /* background: rgb(7, 110, 50); */
  /* color: white; */
  font-size: 2.3em;
  padding: 0;
}





.addSubscription {
  grid-column: 1 /-1;
  display: grid;
  grid-template-columns: 1fr 125px;
  align-items: center;
}

.addSubscription h3 {
  grid-column: 1/3;
  grid-row: 1/2;
}

.add_notification {
  grid-column: 2/3;
  justify-self: end;
  margin: 3vh 3vh 3vh 3vh;
}

.css-10nd86i {
  grid-column: 1/2;
  grid-row: 2/3;
  margin: 3vh 0vh 3vh 3vh;
}

.page_num {
padding: 0 4vw 0 4vw;
}

.next_prev {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 1 /-1;
}

.question {
  font-size: calc(10px + 2vmin);
  font-weight: 700;
}

.faq:nth-child(odd) {
  background-color:  rgb(241, 238, 238);
}

.faq {
  padding: 1vh 5vw 1vh 5vw;
  grid-column: 1 /-1;
}

.faq-section {
  grid-column: 1 /-1;
}

.text_status{
  text-align: center;
}

.btn {
  width: 75px;
  height: 50px;
  text-align: center;
  color: black;
  font-size: 2.3em;
  padding: 0;
  border: 2px solid ; 
}


.red {
  color: red;
  font-weight: 900;
}

.light-grey{
  color: rgb(172, 170, 170)
}


